import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Col, Row } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import InputText from '@uz/unitz-components-web/InputText';
import gstyles from '@vl/gstyles';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import _ from 'lodash';
import styled from 'styled-components';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form, FormItem } from 'formik-antd';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cx from 'classnames';
import AddTemplateModal from './modals/AddTemplateModal';
import EditTemplateModal from './modals/edit';
import CourseItemUseProgramX from '@uz/unitz-tool-components/CourseItemUseProgramX';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';

const CustomInput = styled.div`
  .ant-form-item {
    margin-bottom: 8px;
  }
  textarea,
  input,
  .ant-picker,
  .ant-radio-group,
  .ant-input-affix-wrapper,
  .ant-input-number {
    border-radius: 8px;
    border-color: ${gstyles.colors.border};
    &::placeholder {
      color: ${gstyles.colors.placeHolder};
      font-size: 16px;
    }
  }
  .ant-input-number {
    overflow: hidden;
  }
  .search_input {
    border-radius: 8px 0 0 8px !important;
  }
  .ant-select {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-radius: 8px;
        border-color: ${gstyles.colors.border};
      }
    }
  }
  .ant-radio-group {
    overflow: hidden;
    .ant-radio-button-wrapper {
      width: 50%;
      border-color: ${gstyles.colors.border};
      text-align: center;
      color: ${gstyles.colors.main};
      font-weight: 400;
      &.ant-radio-button-wrapper-checked {
        color: ${gstyles.colors.white500};
        font-weight: bold;
      }
      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }
  span {
    .ant-upload {
      border-radius: 8px;
      border-color: ${gstyles.colors.border};
      background-color: ${gstyles.colors.background2};
      .ant-upload.ant-upload-btn {
        padding: 0;
      }
    }
  }
`;

const gutter = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};

const ScrollX = styled.div`
  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background-color: ${gstyles.colors.background2};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${gstyles.colors.placeHolder};
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: ${gstyles.colors.black200};
  }
`;

const View8 = () => {
  const [isVisible, $isVisible] = React.useState(false);
  const [isVisibleEditModal, $isVisibleEditModal] = React.useState(false);
  const openModal = () => {
    $isVisible(true);
  };
  const hideModal = () => {
    $isVisible(false);
  };

  const openEditModal = () => {
    $isVisibleEditModal(true);
  };

  const hideEditModal = () => {
    $isVisibleEditModal(false);
  };

  return (
    <DIV className="component">
      <DIV forceCtx>
        <Row gutter={gutter}>
          <Col span={6}>
            <div className="bg-background1 px-2 py-3 rounded-lg">
              <DragDropContext onDragEnd={ctx.get('dndModel.handleDrop')}>
                <Droppable droppableId="list-container">
                  {(provided) => (
                    <ScrollX
                      style={{
                        overflowY: ctx.get('dndModel.itemList.length') > 10 ? 'scroll' : 'auto',
                        height: ctx.get('dndModel.itemList.length') > 10 ? 650 : 'auto',
                      }}
                      className="list-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {_.map(ctx.get('dndModel.itemList'), (item, index) => (
                        <Draggable key={index} draggableId={`lesson_${index}`} index={index}>
                          {(provided) => (
                            <div
                              className="item-container"
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                            >
                              <div onClick={() => ctx.apply('dndModel.setCurrent', item.id)}>
                                <div
                                  className={cx('p-4 mb-2 flex items-center justify-between rounded-lg', {
                                    'bg-transparent': ctx.get('dndModel.current') !== item.id,
                                    'bg-brandb500': ctx.get('dndModel.current') === item.id,
                                  })}
                                >
                                  <div className="flex items-center space-x-2">
                                    <div className="cursor-move">
                                      {gstyles.icons({
                                        name: 'drag-horizontal',
                                        fill:
                                          ctx.get('dndModel.current') === item.id
                                            ? gstyles.colors.white500
                                            : gstyles.colors.sub,
                                        size: 16,
                                      })}
                                    </div>
                                    <div
                                      className={cx('text-sm', {
                                        'text-main': ctx.get('dndModel.current') !== item.id,
                                        'text-white500': ctx.get('dndModel.current') === item.id,
                                      })}
                                    >
                                      {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.course')} {index + 1}
                                    </div>
                                  </div>
                                  <div
                                    className="text-main cursor-pointer"
                                    onClick={() => ctx.apply('form.array.remove', 'lessons', index)}
                                  >
                                    {gstyles.icons({
                                      name: 'bin',
                                      fill:
                                        ctx.get('dndModel.current') === item.id
                                          ? gstyles.colors.white500
                                          : gstyles.colors.sub,
                                      size: 24,
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ScrollX>
                  )}
                </Droppable>
              </DragDropContext>
              <Button
                type="outline"
                size="small"
                className="mt-4 w-full"
                onClick={() => ctx.apply('form.array.add', 'lessons')}
                icon={gstyles.icons({
                  name: 'add-circle',
                  size: 20,
                  fill: gstyles.colors.brandb500,
                  className: 'inline-block mx-2',
                })}
              >
                {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Form.add_course')}
              </Button>
            </div>
          </Col>
          <Col span={18}>
            {_.map(ctx.get('form.values.lessons'), (item, index) => {
              if (ctx.get('dndModel.current') === item.id) {
                ctx.set('lessonIndex', index);
                return (
                  <div className="steps-content" key={index}>
                    <DIV forceCtx>
                      {renderPropsComposer(
                        {
                          matcher: (props) => !_.get(props, 'course_id'),
                          render: () => (
                            <Row justify="center" className="py-16">
                              <Col>
                                <div className="flex flex-col items-center justify-center">
                                  <div className="mb-4">
                                    {gstyles.icons({
                                      name: 'courseEmpty',
                                      size: 152,
                                    })}
                                  </div>
                                  <div className="mb-4 text-2xl text-center font-semibold text-sub">
                                    {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.create_course_state_title')}
                                  </div>
                                  <Button type="primary" onClick={openModal} disabled>
                                    <div className="flex items-center">
                                      <div className="text-white500 font-semibold">
                                        {ctx.apply('i18n.t', 'ZoomToolProgram.Action.add_course_template')}
                                      </div>
                                      <div>
                                        {gstyles.icons({
                                          name: 'plus',
                                          size: 14,
                                          fill: gstyles.colors.white500,
                                          className: 'mx-2',
                                        })}
                                      </div>
                                    </div>
                                  </Button>
                                  <AddTemplateModal visible={isVisible} hide={hideModal} />
                                </div>
                              </Col>
                            </Row>
                          ),
                        },
                        {
                          matcher: (props) => !!_.get(props, 'course_id'),
                          render: (props) => (
                            <>
                              <div className="text-sub my-3 font-semibold text-sm">
                                {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.selected_course_template')}
                              </div>
                              <div className="mb-8 p-8 w-full bg-brandb500 border rounded-lg">
                                <Row align="middle" justify="space-between">
                                  <Col>
                                    <Row gutter={[16, 0]} align="middle">
                                      <Col>
                                        <Avatar
                                          alt={_.get(props, 'name')}
                                          size={40}
                                          src={CourseFormatter.avatarUrl(ctx)(props)}
                                        />
                                      </Col>
                                      <Col>
                                        <div className="uppercase text-base text-white500 font-semibold">
                                          {_.get(props, 'name')}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col>
                                    <Row gutter={[16, 0]}>
                                      <Col>
                                        <Button style={{ background: gstyles.colors.white500 }} onClick={openEditModal}>
                                          {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Action.edit')}
                                        </Button>
                                        {ctx.debug(() => {
                                          const id = _.get(props, 'course_id', '');
                                          ctx.set('id', id);
                                        })}
                                        <EditTemplateModal visible={isVisibleEditModal} hide={hideEditModal} />
                                      </Col>
                                      <Col>
                                        <Button
                                          style={{ background: gstyles.colors.white500 }}
                                          onClick={() => ctx.apply('form.array.reset', `lessons[${index}]`)}
                                        >
                                          {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Action.remove')}
                                        </Button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </>
                          ),
                        },
                        () => null
                      )(ctx.get(`form.values.lessons[${index}]`))}
                      <FormProvider form={ctx.get('searchForm')}>
                        <Form className="search-form">
                          <Row>
                            <Col span={10}>
                              <FormItem
                                name="search"
                                label={
                                  <span className="text-sm font-semibold text-sub">
                                    {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Form.search_label1')}
                                  </span>
                                }
                              >
                                <div className="text-sm font-semibold text-sub mb-2">
                                  {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Form.search_label2')}
                                </div>
                                <Row align="middle">
                                  <Col span={21}>
                                    <CustomInput>
                                      <InputText
                                        className="search_input"
                                        name="search"
                                        size="medium"
                                        placeHolder={ctx.apply(
                                          'i18n.t',
                                          'ZoomToolProgramTemplate.Form.search_placeholder'
                                        )}
                                      />
                                    </CustomInput>
                                  </Col>
                                  <Col span={1}>
                                    <button
                                      style={{ width: 40, height: 40, borderRadius: '0 8px 8px 0' }}
                                      className="bg-brandb500 flex justify-center items-center"
                                      onClick={ctx.get('searchForm.handleSubmit')}
                                    >
                                      {gstyles.icons({
                                        name: 'search',
                                        fill: gstyles.colors.white500,
                                        size: 24,
                                      })}
                                    </button>
                                  </Col>
                                </Row>
                              </FormItem>
                            </Col>
                          </Row>
                          {renderPropsComposer(
                            {
                              matcher: (props) => !_.get(props, 'length'),
                              render: () => (
                                <Row justify="center" className="py-6">
                                  <Col>
                                    <div className="flex flex-col items-center justify-center">
                                      <div className="mb-4">
                                        {gstyles.icons({
                                          name: 'courseEmpty',
                                          size: 152,
                                        })}
                                      </div>
                                      <div className="mb-4 text-2xl text-center font-semibold text-sub">
                                        {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.empty_course_state_title')}
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              ),
                            },
                            {
                              matcher: (props) => !!_.get(props, 'length'),
                              render: () => (
                                <Row gutter={[0, 16]} justify="center" className="py-4">
                                  {_.map(ctx.get('courses.items'), (item, courseIdx) => (
                                    <React.Fragment key={courseIdx}>
                                      <DIV forceCtx>
                                        {ctx.debug(() => {
                                          ctx.set('@item', item);
                                          ctx.set('@idx', index);
                                        })}
                                        <CourseItemUseProgramX />
                                      </DIV>
                                    </React.Fragment>
                                  ))}
                                </Row>
                              ),
                            },
                            () => null
                          )(ctx.get('courses.items'))}
                        </Form>
                      </FormProvider>
                    </DIV>
                  </div>
                );
              }
            })}
          </Col>
        </Row>
      </DIV>
    </DIV>
  );
};

export default displayName(View8);
