const _ = require('lodash');
import de_DE from 'antd/lib/date-picker/locale/de_DE';
import en_US from 'antd/lib/date-picker/locale/en_US';

export const datePickerLocaleMapping = (currentLocale) => {
  const localeMapping = {
    en: en_US,
    de: de_DE,
  };

  return _.get(localeMapping, currentLocale, 'de');
};

export default datePickerLocaleMapping;
