import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            courseTemplateItemInfo: hook((ctx) => {
              const course_id = ctx.get('@item.id');

              const index = ctx.get('@idx');
              return {
                ...ctx.get('@item'),
                isUse: ctx.get(`form.values.lessons[${index}].course_id`) === course_id,
                useCourseTemplate: async () => {
                  try {
                    await ctx.apply('form.setFieldValue', `lessons[${index}]`, {
                      id: ctx.get(`form.values.lessons[${index}].id`),
                      course_id: '',
                      name: '',
                      photo_url: '',
                      description: '',
                    });
                    ctx.apply('form.setFieldValue', `lessons[${index}]`, {
                      id: ctx.get(`form.values.lessons[${index}].id`),
                      course_id: course_id,
                      name: ctx.get('@item.name'),
                      photo_url: ctx.get('@item.photo_url'),
                      description: ctx.get('@item.description'),
                    });
                  } catch (err) {
                    console.log(err);
                  }
                },
                // deleteCourseTemplate: async () => {
                //   try {
                //     const payload = {
                //       user_id: ctx.apply('currentUserModel.getUserId'),
                //       account_id: ctx.get('@item.account_id'),
                //       template_id: template_id,
                //     };

                //     const res = await B2BCourseTemplateModel.deleteCourseTemplate(payload);
                //     if (!!res) ctx.apply('paginationModel.onRefresh');
                //   } catch (err) {
                //     console.log(err);
                //   }
                // },
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
