import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Modal } from 'antd';
import gstyles from '@vl/gstyles';
import FormEditCourseTemplateModal from '@uz/unitz-tool-components/FormEditCourseTemplateModal';

export const View8 = ({ visible, hide }) => {
  return (
    <DIV className="component">
      <Modal
        bodyStyle={{ padding: 0 }}
        footer={null}
        className="rounded-lg overflow-hidden"
        maskStyle={{ background: gstyles.colors.black300 }}
        visible={visible}
        width={1248}
        centered
        closeIcon={
          <div className="py-3 mb-4">{gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.sub })}</div>
        }
        wrapClassName={'my-modal'}
        onCancel={hide}
      >
        <div className="w-full py-6 px-8">
          <div style={{ boxShadow: '0px 1px 6px rgba(38, 51, 64, 0.18)', borderRadius: 12 }} className="rounded-xl p-6">
            <DIV className="isLoading">Loading....</DIV>
            <DIV className="notLoading">
              {ctx.debug(() => {
                ctx.set('hideModal', hide);
              })}
              <FormEditCourseTemplateModal />
            </DIV>
          </div>
        </div>
      </Modal>
    </DIV>
  );
};

export default displayName(View8);
