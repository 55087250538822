import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { Row, Col } from 'antd';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import gstyles from '@vl/gstyles';
import Button from '@uz/unitz-components-web/Button';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';

const Index = () => {
  return (
    <DIV className="component">
      <>
        {renderPropsComposer(
          {
            matcher: (props) => _.get(props, 'items.length') < _.get(props, 'total') && !!_.get(props, 'items.length'),
            render: () => (
              <ResponsiveProps xs={{ justify: 'center' }} md={{ justify: 'center' }} lg={{ justify: 'end' }}>
                {(resProps) => (
                  <Row className="mt-6" {...resProps}>
                    <Col>
                      <div className="flex items-center">
                        <div className="table-cell align-top pr-4">
                          <Button
                            name="ink"
                            disabled={!ctx.get('paginationModel.hasPreviousPage')}
                            onClick={ctx.get('paginationModel.onPreviousPage')}
                          >
                            <div className="flex items-center space-x-2">
                              <div>{gstyles.icons({ name: 'arrow-left', size: 20 })}</div>
                              <div className="hidden md:block">{ctx.apply('i18n.t', 'Biz.Blog.prev')}</div>
                            </div>
                          </Button>
                        </div>
                        <div className="table-cell align-top pr-4">
                          {ctx.apply('i18n.t', 'Biz.Blog.page')} {ctx.get('paginationModel.page') + 1}{' '}
                          {ctx.apply('i18n.t', 'Biz.Blog.of')}{' '}
                          {Math.ceil(ctx.get('paginationModel.total') / ctx.get('paginationModel.pageSize'))}
                        </div>
                        <div className="table-cell align-top pr-4">
                          <Button
                            name="ink"
                            disabled={!ctx.get('paginationModel.hasNextPage')}
                            onClick={ctx.get('paginationModel.onNextPage')}
                          >
                            <div className="hidden md:flex items-center space-x-2">
                              <div>{ctx.apply('i18n.t', 'Biz.Blog.next')}</div>
                              <div>{gstyles.icons({ name: 'arrow-right', size: 20 })}</div>
                            </div>
                            <div className="block md:hidden">{gstyles.icons({ name: 'arrow-right', size: 20 })}</div>
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
              </ResponsiveProps>
            ),
          },
          () => null
        )(ctx.get('paginationModel'))}
      </>
    </DIV>
  );
};

export default displayName(Index);
