import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import _ from 'lodash';
import { Row, Col } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import CourseFormatter from '@uz/unitz-models/CourseModel/formatter';

const View8 = () => {
  return (
    <DIV className="component">
      <div className="p-8 w-full bg-white500 border border-border rounded-lg">
        <Row align="middle" justify="space-between">
          <Col>
            <Row gutter={[16, 0]} align="middle">
              <Col>
                <Avatar
                  alt={ctx.get('courseTemplateItemInfo.name')}
                  size={40}
                  src={CourseFormatter.avatarUrl(ctx)(ctx.get('courseTemplateItemInfo'))}
                />
              </Col>
              <Col>
                <div className="uppercase text-base text-main font-semibold">
                  {ctx.get('courseTemplateItemInfo.name')}
                </div>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row gutter={[16, 16]}>
              <Col>
                <Button
                  size="medium"
                  type="primary"
                  disabled={ctx.get('courseTemplateItemInfo.isUse')}
                  onClick={ctx.get('courseTemplateItemInfo.useCourseTemplate')}
                >
                  {ctx.apply('i18n.t', 'ZoomToolProgramTemplate.Action.use')}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </DIV>
  );
};

export default displayName(View8);
