import React from 'react';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';

const CustomEditor = styled.div`
  .text-editor {
    .ql-toolbar {
      border-radius: 8px 8px 0 0;
      border-color: ${gstyles.colors.border};
    }
    .quill {
      .ql-container {
        border-radius: 0 0 8px 8px;
        border-color: ${gstyles.colors.border};
        .ql-editor {
          &::before {
            color: ${gstyles.colors.placeHolder};
          }
        }
      }
    }
  }
`;

export default CustomEditor;
