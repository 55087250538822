import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import { useFormik } from 'formik';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            dndModel: hook((ctx) => {
              const lessonList = React.useMemo(() => ctx.get('form.values.lessons'), [
                ctx.get('form.values.lessons.length'),
              ]);
              const [itemList, setItemList] = React.useState(lessonList);
              const [current, setCurrent] = React.useState(!itemList ? null : 0);
              React.useEffect(() => {
                setItemList(lessonList);
              }, [ctx.get('form.values.lessons.length')]);

              const handleDrop = (droppedItem) => {
                // Ignore drop outside droppable container
                if (!droppedItem.destination) return;
                var updatedList = [...itemList];
                // Remove dragged item
                const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
                // Add dropped item
                updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
                // Update State
                setItemList(updatedList);
              };
              const activeLesson = () => {
                const findData = _.find(itemList, (item) => {
                  return item.id === current;
                });

                return findData;
              };
              return {
                current,
                setCurrent,
                activeLesson,
                itemList,
                handleDrop,
              };
            }),

            courseTemplateUsed: hook((ctx) => {
              return [];
            }),

            searchForm: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const account_id = ctx.apply('accountModel.getAccountId');
              const form = useFormik({
                initialValues: {
                  search: '',
                },
                onSubmit: async (values, actions) => {
                  try {
                    const payload = {
                      search: values.search,
                    };

                    if (!values.search) return null;
                    const client = await getClient();
                    const template_data = await client.request(
                      gql`
                        query SearchCourse($args: b2b_search_course_args = {}) {
                          b2b_search_course(args: $args, where: {account_id: {_eq: "${account_id}"}}) {
                            id
                            account_id
                            name
                            description
                            link_urls
                            materials
                            photo_url
                            course_rooms {
                              name
                              description
                              link_urls
                              materials
                              photo_url
                            }
                          }
                        }
                      `,
                      { args: payload }
                    );
                    ctx.apply('courses.setItem', template_data);
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({}),
              });
              return form;
            }),

            courses: hook((ctx) => {
              const [items, $items] = React.useState([]);
              const filterer = React.useMemo(
                () =>
                  _.filter(_.get(items, 'b2b_search_course', []), (item) => {
                    return _.some(ctx.get('form.values.lessons'), (item_values) => {
                      return _.get(item, 'id', '') !== _.get(item_values, '', '');
                    });
                  }),
                [items]
              );
              return {
                items: filterer,
                setItem: $items,
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
