import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import _ from 'lodash';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import InputText from '@uz/unitz-components-web/InputText';
import { FormItem } from 'formik-antd';
import CustomInput from '@uz/unitz-components-web/CustomInput';

const CustomFormItem = styled.div`
  .ant-form-item {
    margin-bottom: 0;
  }
`;
const ToolSearchInput = ({ name, label, placeholder, onClick, onKeyDown }) => {
  return (
    <DIV className="component">
      <CustomFormItem>
        <FormItem name={name} label={<div className="text-sm font-semibold text-sub">{label && label}</div>}>
          <div className="flex items-center w-full">
            <div className="w-full">
              <CustomInput>
                <InputText
                  className="search_input border-divide"
                  size="medium"
                  name={name}
                  placeholder={placeholder && placeholder}
                  onKeyDown={onKeyDown && onKeyDown}
                />
              </CustomInput>
            </div>
            <div>
              <div
                style={{ width: 40, height: 40, borderRadius: '0 8px 8px 0' }}
                className="bg-brandb500 flex justify-center items-center"
                onClick={onClick && onClick}
                disabled={ctx.get('paginationModel.isLoading')}
              >
                {gstyles.icons({
                  name: 'search',
                  fill: gstyles.colors.white500,
                  size: 24,
                })}
              </div>
            </div>
          </div>
        </FormItem>
      </CustomFormItem>
    </DIV>
  );
};

export default ToolSearchInput;
